$(document).ready((function () {
  var jqueryMap = {
    $container: null
  }; var configMap = {
    innerHTML:
        `<div class='landingMapContainer'>
            <div class='normal'>
                <div class='UBOT-logo'>
                    <a href='/home'>
                        <img src='images/portlet/UBOT-landPage-portlet/logo.png' alt='聯邦銀行' title='聯邦銀行'/>
                    </a>
                </div>
                <div class='UBOT-padlogo'>
                    <a href='/home'>
                        <img src='images/portlet/UBOT-landPage-portlet/erectedPad/logo.png' alt='聯邦標示圖' title='聯邦標示圖'/>
                    </a>
                </div>
                <div class='UBOT-phonelogo'>
                    <a href='/home'>
                        <img src='images/portlet/UBOT-landPage-portlet/phone/logo.png' alt='聯邦標示圖' title='聯邦標示圖'/></a></div>
                <div class='landingMapBlock'>
                    <div class='clearBoth'></div>
                    <div class='indexEntrance'><a href='/home'> <img src='images/portlet/UBOT-landPage-portlet/index_bar.jpg' alt='聯邦銀行首頁進入標示' title='點此進入聯邦銀行首頁'/> </a></div>
                    <div class='normal_enterpriseFinancial'><a href='/enterprisefinancial'> <img src='images/portlet/UBOT-landPage-portlet/index_business.jpg' alt='企業金融網頁進入標示圖' title='進入企業金融網頁'/> </a></div>
                    <div class='normal_myFinancial'><a href='/myfinancial'> <img src='images/portlet/UBOT-landPage-portlet/index_personal.jpg' alt='個人金融網頁進入標示圖' title='進入個人金融網頁'/> </a></div>
                    <div class='normal_ebank'><img src='images/portlet/UBOT-landPage-portlet/login.png' alt='網路銀行進入背景' title='網路銀行進入背景'/>
                        <div class='loginWraper'>
                            <div class='Login_personal'><img src='images/portlet/UBOT-landPage-portlet/login_personal.png' alt='登入個人網路銀行' title='進入個人網路銀行登入頁面'/></div>
                            <div class='Login_enterprise'><img src='images/portlet/UBOT-landPage-portlet/login_business.png' alt='登入企業網路銀行' title='進入聯邦ｅ聯網登入頁面'/></div>
                        </div>
                    </div>
                    <div class='normal_manageAsset'><a href='https://www.yesfund.com.tw/index.html'><img src='images/portlet/UBOT-landPage-portlet/wealth.jpg' alt='財富管理標示圖' title='進入財富管理頁面'/> </a></div>
                    <div class='normal_apps'><a href='https://newnewbank.com.tw/'><img src='images/portlet/UBOT-landPage-portlet/app.png' alt='樂活APP標示圖' TITLE='進入樂活App頁面'/> </a></div>
                    <div class='normal_serviceLocate'><a href='/service_location'><img src='images/portlet/UBOT-landPage-portlet/location.png' alt='服務據點標示圖' title='進入服務據點頁面'/></a></div>
                    <div class='normal_rateinTime'><a href='https://mybank.ubot.com.tw/ubot/#/B0401001A?type=1'><img src='images/portlet/UBOT-landPage-portlet/rate.png' alt='即時匯利率標示圖' title='進入即時匯利率頁面'/> </a></div>
                    <div class='normal_credit'><a href='https://card.ubot.com.tw/eCard/'><img src='images/portlet/UBOT-landPage-portlet/card.jpg' alt='信用卡標示圖' title='進入信用卡頁面'/> </a></div>
                    <div class='normal_eATM'><a href='https://web.ubot.com.tw/UB/2017ubpay/index.html '><img src='images/portlet/UBOT-landPage-portlet/atm.png' alt='網路ATM標示圖' title='進入行動支付頁面'/> </a></div>
                </div>
            </div>
            <div class='erectedPad'>
                <div class='pad_IndexEntrance'><a href='/home'> <img src='images/portlet/UBOT-landPage-portlet/erectedPad/index.png' alt='聯邦銀行首頁進入標示' title='點此進入聯邦銀行首頁'/> </a></div>
                <div class='pad_ebank'><img src='images/portlet/UBOT-landPage-portlet/erectedPad/login_bg.png' alt='網路銀行進入背景' title='網路銀行進入背景'/>
                    <div class='loginWraper'>
                        <div class='Login_personal'><img src='images/portlet/UBOT-landPage-portlet/erectedPad/login_personal.png' alt='登入個人網路銀行' title='進入個人網路銀行登入頁面'/></div>
                        <div class='Login_enterprise'><img src='images/portlet/UBOT-landPage-portlet/erectedPad/login_business.png' alt='登入企業網路銀行' title='進入聯邦ｅ聯網登入頁面'/></div>
                    </div>
                </div>
                <div class='pad_enterpriseFinancial'><a href='enterprisefinancial'><img src='images/portlet/UBOT-landPage-portlet/erectedPad/index_business.png' alt='企業金融標示圖' title='進入企業金融頁面'/> </a></div>
                <div class='pad_myFinancial'><a href='myfinancial'><img src='images/portlet/UBOT-landPage-portlet/erectedPad/index_personal.png' alt='個人金融標示圖' title='進入個人金融頁面'/> </a></div>
                <div class='pad_credit'><a href='https://card.ubot.com.tw/eCard/'><img src='images/portlet/UBOT-landPage-portlet/erectedPad/card.png' alt='信用卡標示圖' title='進入信用卡頁面'/> </a></div>
                <div class='pad_manageAsset'><a href='https://www.yesfund.com.tw/index.html'><img src='images/portlet/UBOT-landPage-portlet/erectedPad/wealth.png' alt='財富管理標示圖' title='進入財富管理頁面'/> </a></div>
                <div class='pad_apps'><a href='https://newnewbank.com.tw/'><img src='images/portlet/UBOT-landPage-portlet/erectedPad/app.png' alt='樂活APP標示圖' TITLE='進入樂活App頁面'/> </a></div>
                <div class='pad_eATM'><a href='https://web.ubot.com.tw/UB/2017ubpay/index.html '><img src='images/portlet/UBOT-landPage-portlet/erectedPad/atm.png' alt='網路ATM標示圖' title='進入行動支付頁面'/> </a></div>
                <div class='pad_serviceLocate'><a href='/service_location'><img src='images/portlet/UBOT-landPage-portlet/erectedPad/location.png' alt='服務據點標示圖' title='進入服務據點頁面'/></a></div>
                <div class='pad_rateinTime'><a href='https://mybank.ubot.com.tw/ubot/#/B0401001A?type=1'><img src='images/portlet/UBOT-landPage-portlet/erectedPad/rate.png' alt='即時匯利率標示圖' title='進入即時匯利率頁面'/> </a></div>
            </div>
            <div class='phone'>
                <div class='phone_ebank'><img src='images/portlet/UBOT-landPage-portlet/phone/login_bg.png' alt='網路銀行進入背景' title='網路銀行進入背景'/></div>
                <div class='phone_Login_personal'><img src='images/portlet/UBOT-landPage-portlet/phone/login_personal.png' alt='登入個人網路銀行' title='進入個人網路銀行登入頁面'/></div>
                <div class='phone_Login_enterprise'><img src='images/portlet/UBOT-landPage-portlet/phone/login_business.png' alt='登入企業網路銀行' title='進入聯邦ｅ聯網登入頁面'/></div>
                <div class='phone_enterpriseFinancial'><a href='enterprisefinancial'><img src='images/portlet/UBOT-landPage-portlet/phone/index_business.jpg' alt='企業金融標示圖' title='進入企業金融頁面'/> </a></div>
                <div class='phone_myFinancial'><a href='myfinancial'><img src='images/portlet/UBOT-landPage-portlet/phone/index_personal.jpg' alt='個人金融標示圖' title='進入個人金融頁面'/> </a></div>
                <div class='phone_manageAsset'><a href='https://www.yesfund.com.tw/index.html'><img src='images/portlet/UBOT-landPage-portlet/phone/wealth.jpg' alt='財富管理標示圖' title='進入財富管理頁面'/> </a></div>
                <div class='phone_apps'><a href='https://newnewbank.com.tw/'><img src='images/portlet/UBOT-landPage-portlet/phone/app.png' alt='樂活APP標示圖' TITLE='進入樂活App頁面'/> </a></div>
                <div class='phone_serviceLocate'><a href='/service_location'> <img src='images/portlet/UBOT-landPage-portlet/phone/location.png' alt='服務據點標示圖' title='進入服務據點頁面'/> </a></div>
                <div class='phone_rateinTime'><a href='https://mybank.ubot.com.tw/ubot/#/B0401001A?type=1'><img src='images/portlet/UBOT-landPage-portlet/phone/rate.png' alt='即時匯利率標示圖' title='進入即時匯利率頁面'/> </a></div>
                <div class='phone_credit'><a href='https://card.ubot.com.tw/eCard/'><img src='images/portlet/UBOT-landPage-portlet/phone/card.jpg' alt='信用卡標示圖' title='進入信用卡頁面'/> </a></div>
                <div class='phone_eATM'><a href='https://web.ubot.com.tw/UB/2017ubpay/index.html '><img src='images/portlet/UBOT-landPage-portlet/phone/atm.png' alt='網路ATM標示圖' title='進入行動支付頁面'/> </a></div>
            </div>
        </div>
        <div class='landingFooter'>
            <div class='wrapper'>
                <div class='normal'>聯邦銀行 版權所有©&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;建議瀏覽器版本：IE10以上&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;解析度 1024×768&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;24&nbsp;小時客戶服務專線：(02)&nbsp;2545-1788&nbsp;/&nbsp;0800-089-888</div>
                <div class='pad_des'>
                    <div class='paraA'>聯邦銀行 版權所有©&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;建議瀏覽器版本：IE10以上&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;解析度 1024×768&nbsp;&nbsp;&nbsp;|</div>
                    <div class='paraB'>24&nbsp;小時客戶服務專線：(02)&nbsp;2545-1788&nbsp;/&nbsp;0800-089-888</div>
                </div>
                <div class='des'>
                    <div class='symble'>©</div>
                    <div class='announce'>聯邦銀行&nbsp;&nbsp;版權所有</div>
                    <div class='yy'>© 2015</div>
                    <div class='advice'>
                        <div class='bar'></div>
                        <div class='browser'>建議瀏覽器版本：IE10以上</div>
                        <div class='bar'></div>
                        <div class='resolution'>解析度 1024×768</div>
                        <div class='bar'></div>
                        <div class='tel'>24小時客戶服務專線：(02) 2545-1788 / 0800-089-888</div>
                    </div>
                </div>
            </div>
        </div>`,
    personalURL: 'https://mybank.ubot.com.tw/',
    enterpriseURL: 'https://myebank.ubot.com.tw/'
  }; var init;
  init = (function () {
    var $container = jqueryMap.$container = $('#landingWrapper');
    $container.append(configMap.innerHTML);
    $container.find('.Login_personal,.phone_Login_personal').click(function () {
      location.href = configMap.personalURL;
    });
    $container.find('.Login_enterprise,.phone_Login_enterprise').click(function () {
      location.href = configMap.enterpriseURL;
    });
  }());
})());
